// display date like 30 seconds ago, 24h ago and then the actual date

export function dateText(date: Date | string): string {
    if (typeof date === 'string') date = new Date(date)

    const diff = new Date().getTime() - date.getTime()
    const seconds = Math.floor(diff / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    if (seconds <= 1) {
        return `${seconds} second ago`
    } else if (seconds < 120) {
        return `${seconds} seconds ago`
    } else if (minutes <= 1) {
        return `${minutes} minute ago`
    } else if (minutes < 60) {
        return `${minutes} minutes ago`
    } else if (hours <= 1) {
        return `${hours} hour ago`
    } else if (hours < 24) {
        return `${hours} hours ago`
    } else if (days <= 1) {
        return `${days} day ago`
    } else if (days < 30) {
        return `${days} days ago`
    } else {
        // actual date in format dd/mm/yyyy
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
    }
}
