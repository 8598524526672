import { Clock, Dices, Eye, Flame, History, Search, View } from 'lucide-react'
import { useEffect, useState } from 'react'

import { ButtonWithAds } from '@/components/ads/ButtonWithAds'
import { Input } from '@/components/ui/input'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { useCredits } from '@/provider/credit-provider'
import { useExternalAds } from '@/provider/external-ads-provider'
import { ContentSorting, Ethnicity } from '@/types/content.type'

interface SearchBoxProps {
    onSearch: (search: string) => void
    initialSearch: string

    sorting: ContentSorting
    onChangeSorting: (sorting: ContentSorting) => void

    ethnicity: Ethnicity
    onChangeEthnicity: (ethnicity: Ethnicity) => void
}

const SearchBox = ({
    onSearch,
    onChangeSorting,
    sorting,
    initialSearch,
    onChangeEthnicity,
    ethnicity,
}: SearchBoxProps) => {
    const [search, setSearch] = useState('')
    const { creditConfig } = useCredits()
    const { openAds } = useExternalAds()

    useEffect(() => {
        setSearch(initialSearch)
    }, [initialSearch])

    return (
        <div className='flex items-center space-x-2  text-sm'>
            {/*<p className='text-sm text-muted-foreground'>Search</p>*/}
            <div
                className={cn(
                    'flex items-center space-x-2 text-sm transition-all duration-300 ease-in-out overflow-hidden',
                    sorting === ContentSorting.RANDOM ? 'w-0' : 'w-10'
                )}
            >
                <ButtonWithAds
                    variant='secondary'
                    size='icon'
                    onClick={() => {
                        onSearch(search)
                    }}
                >
                    <Search className='h-4 w-4' />
                </ButtonWithAds>
            </div>

            <form
                onSubmit={(event) => {
                    event.preventDefault()
                    onSearch(search)
                }}
                className={cn(
                    'flex items-center space-x-2 w-auto text-sm p-1 transition-all duration-300 ease-in-out overflow-hidden',
                    sorting === ContentSorting.RANDOM ? 'w-0' : 'w-44'
                )}
            >
                <Input
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value)
                    }}
                    onClick={() => {
                        openAds()
                    }}
                    onClear={() => {
                        setSearch('')
                        onSearch('')
                    }}
                    type='search'
                    placeholder='Search...'
                />
            </form>

            <Select
                value={sorting}
                onOpenChange={() => {
                    openAds()
                }}
                onValueChange={(value) => {
                    onChangeSorting(value as ContentSorting)
                }}
            >
                <SelectTrigger className='w-35 overflow-hidden'>
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem value={ContentSorting.NEWEST}>
                            <Clock className='h-4 w-4 inline-block mr-2 ' />
                            <div className='inline-block font-sans'>New</div>
                        </SelectItem>
                        <SelectItem value={ContentSorting.HOT}>
                            <Flame className='h-4 w-4 inline-block mr-2' />
                            <div className='inline-block font-sans'>Hot</div>
                        </SelectItem>

                        <SelectItem value={ContentSorting.VIEWS_ALL_TIME}>
                            <Eye className='h-4 w-4 inline-block mr-2' />
                            <div className='inline-block font-sans'>Views</div>
                        </SelectItem>
                        <SelectItem value={ContentSorting.RANDOM}>
                            <Dices className='h-4 w-4 inline-block mr-2 ' />
                            <div className='inline-block font-sans'>Random</div>
                        </SelectItem>
                        <SelectItem
                            disabled={creditConfig.anonymous}
                            className='flex flex-col bg-gradient-to-r from-account-bg to-transparent '
                            value={ContentSorting.USER_VIEWED}
                        >
                            <div>
                                <View className='h-4 w-4 inline-block mr-2 ' />
                                <div className='inline-block font-sans'>History</div>
                            </div>
                            {creditConfig.anonymous && (
                                <p className='text-muted-foreground  font-light'>with Account</p>
                            )}
                        </SelectItem>
                        {/*<SelectItem value={ContentSorting.SIZE}>*/}
                        {/*    <FolderUp className='h-4 w-4 inline-block mr-2 ' />*/}
                        {/*    <div className='inline-block font-sans'>Size</div>*/}
                        {/*</SelectItem>*/}
                        <SelectItem value={ContentSorting.OLDEST}>
                            <History className='h-4 w-4 inline-block mr-2 ' />
                            <div className='inline-block font-sans'>Oldest</div>
                        </SelectItem>
                    </SelectGroup>
                </SelectContent>
            </Select>
        </div>
    )
}

export default SearchBox
