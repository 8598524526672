import { useClipboard } from '@mantine/hooks'
import {
    ClipboardIcon,
    Eye,
    Film,
    Flame,
    History,
    Image as IconImage,
    Lock,
    Package,
    Radar,
    Share2,
    UploadIcon,
} from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { event } from 'nextjs-google-analytics'
import * as React from 'react'
import { toast } from 'sonner'

import { ButtonWithAds } from '@/components/ads/ButtonWithAds'
import MegaIcon from '@/components/icons/mega'
import { Button } from '@/components/ui/button'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { dateText } from '@/lib/date-text'
import sizeFormat from '@/lib/sizeFormat'
import { cn } from '@/lib/utils'
import { useCredits } from '@/provider/credit-provider'
import { useExternalAds } from '@/provider/external-ads-provider'
import useQuery from '@/provider/query-provider'
import { Content } from '@/types/content.type'

export enum DisplayMode {
    COVER = 'cover',
    CONTAIN = 'contain',
}

export enum CardType {
    CONTENT_GRID = 'content-grid',
    CONTENT_DRAWER = 'content-drawer',
    CONTENT_REDIRECT = 'content-redirect',
}

// React.ComponentProps<typeof Card> and Content
type CardProps = React.ComponentProps<typeof Card>

const ContentCard = ({
    className,
    displayMode,
    contentData,
    cardType,
    ...props
}: CardProps & { displayMode: DisplayMode; contentData?: Content; cardType: CardType }) => {
    const clipboard = useClipboard({ timeout: 500 })
    const { setParam } = useQuery()
    const { credits, creditConfig } = useCredits()
    const { openAds } = useExternalAds()

    if (!contentData) return <Skeleton className={cn('w-full aspect-[2/3.3] rounded-xl', className)} />

    const { title, isPremium, shortCode, viewed, media, mediaAspectRatio, info, views, createdAt, id } =
        contentData

    const cantOpen =
        credits <= 0 || cardType == CardType.CONTENT_REDIRECT || (isPremium && creditConfig.planFree)

    const openDrawer = () => {
        setParam('leak', id)
    }

    const openCredits = () => {
        setParam('credits', 'true')
    }

    const shareToClipboard = () => {
        event('share', {
            category: 'content',
        })
        clipboard.copy(`https://s.leakshaven.com/${shortCode}`)
        toast('Link Copied!', {
            description: 'Share-Link copied to clipboard',
            dismissible: true,
            duration: 2000,
            icon: <ClipboardIcon className='h-4 w-4 mr-1' />,
        })
    }

    return (
        <Card
            data-vaul-no-drag
            key={id}
            className={cn('relative group flex flex-col overflow-hidden z-10 bg-transparent', className)}
            {...props}
        >
            <div className='absolute inset-0  z-[-1] bg-card'>
                <Image
                    src={media}
                    alt={title}
                    width='300'
                    height={300 * mediaAspectRatio}
                    className='w-full h-full object-cover scale-110 blur-lg opacity-40 transition-all group-hover:opacity-30'
                />
            </div>
            <div className='absolute top-1 right-1 flex flex-row gap-2'>
                {viewed && (
                    <div className='flex items-center bg-muted p-1 pr-2 pl-2 rounded-sm '>
                        <Eye className='h-4 w-4' />
                    </div>
                )}

                {views > 20 && (
                    <div className='flex bg-primary  p-1 pr-2 pl-2 rounded-sm text-primary-foreground'>
                        <Flame className='h-4 w-4 ' />
                    </div>
                )}

                {isPremium && (
                    <div className='flex items-center items-center bg-primary p-1 pr-2 pl-2 rounded-sm text-primary-foreground primary-shadow  space-x-2'>
                        <div className='text-xs'>Premium </div>
                        <Lock className='h-4 w-4 ' />
                    </div>
                )}
            </div>
            <CardHeader className='flex flex-grow bg-card p-3  rounded-md m-2 mt-3 shadow-sm'>
                <CardTitle className='flex flex-grow overflow-hidden pb-2'>{title}</CardTitle>
                <CardDescription className=''>
                    <div className='flex flex-row items-center text-sm text-secondary-foreground flex-wrap'>
                        <div className=' flex items-center mr-2'>
                            <Package className='h-4 w-4 mr-1' />
                            {sizeFormat(info.size)}
                        </div>
                        <div className=' flex items-center mr-2'>
                            <IconImage className='h-4 w-4 mr-1' />
                            {info.images}
                        </div>

                        <div className='flex items-center mr-2'>
                            <Film className='h-4 w-4 mr-1' />
                            {info.videos}
                        </div>
                        {createdAt != info.updatedAt && (
                            <div className='flex items-center flex-row mr-2 justify-center'>
                                <History className='h-4 w-4 mr-1' />
                                <p>{dateText(info.updatedAt)}</p>
                            </div>
                        )}
                    </div>
                    {/*{process.env.NEXT_PUBLIC_ENV_DEV_MODE && (*/}
                    {/*    <div className='flex items-center '>Views: {views}</div>*/}
                    {/*)}*/}
                    <div className='flex flex-row items-center text-sm mt-1 flex-wrap'>
                        <div className='flex items-center flex-row mr-2 justify-center'>
                            <UploadIcon className='h-4 w-4 mr-1 mt-[1px]' />
                            <p>{dateText(createdAt)}</p>
                        </div>
                    </div>
                </CardDescription>
            </CardHeader>
            <CardContent className='m-3 pb-0 mb-4 pl-0 pr-0 relative overflow-hidden transition-all group-hover:shadow-card rounded-md'>
                {displayMode === DisplayMode.COVER ? (
                    <div className='relative overflow-hidden w-full aspect-square'>
                        <Link
                            href={
                                cantOpen
                                    ? '#'
                                    : {
                                          pathname: '/get-link',
                                          query: { leak: id },
                                      }
                            }
                            target='_blank'
                            onClick={(event) => {
                                if (cantOpen) {
                                    event.preventDefault()
                                } else {
                                    openAds(event)
                                }
                            }}
                        >
                            <Image
                                width={500}
                                height={500 * mediaAspectRatio}
                                src={media}
                                alt={title}
                                className={`absolute inset-0 top-0  object-contain transition-all group-hover:scale-110  cursor-pointer`}
                            />
                        </Link>
                    </div>
                ) : (
                    <Link
                        href={
                            cantOpen
                                ? '#'
                                : {
                                      pathname: '/get-link',
                                      query: { leak: id },
                                  }
                        }
                        target='_blank'
                        onClick={(event) => {
                            if (cantOpen) {
                                event.preventDefault()
                            } else {
                                openAds(event)
                            }
                        }}
                    >
                        <Image
                            width={500}
                            height={500 * mediaAspectRatio}
                            src={media}
                            alt={title}
                            className='w-full aspect-square object-contain  transition-all group-hover:scale-110 cursor-pointer'
                        />
                    </Link>
                )}
            </CardContent>
            {cardType !== CardType.CONTENT_REDIRECT && (
                <CardFooter className='flex items-center ml-2 mr-2 p-2 pt-0  pb-3 space-x-2'>
                    {isPremium && creditConfig.planFree ? (
                        <Button
                            asChild
                            variant='destructive'
                            className=' cursor-pointer get-content flex-grow '
                        >
                            <Link href='/plans'>
                                <div className='flex items-center justify-between '>
                                    <Lock className='mr-2 h-4 w-4 ' />
                                    <div className='flex justify-start flex-col '>
                                        <div className='text-sm'>Get Premium</div>
                                        <div className='text-xs text-muted-foreground'>
                                            Unlock content with Premium
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Button>
                    ) : credits > 0 ? (
                        <Button
                            variant='secondary'
                            className='cursor-pointer get-content flex-grow'
                            asChild
                        >
                            <Link
                                href={{
                                    pathname: '/get-link',
                                    query: { leak: id },
                                }}
                                onClick={(event) => {
                                    openAds(event)
                                }}
                                target='_blank'
                            >
                                <MegaIcon className='mr-2 h-4 w-4 accent-white' />
                                Open Link
                            </Link>
                        </Button>
                    ) : (
                        <Button
                            onClick={openCredits}
                            variant='destructive'
                            className='cursor-pointer get-content flex-grow'
                        >
                            Get Credits
                        </Button>
                    )}

                    {cardType === CardType.CONTENT_GRID && !isPremium && (
                        <ButtonWithAds
                            onClick={openDrawer}
                            variant='secondary'
                            className='cursor-pointer open-related flex-grow'
                        >
                            <Radar className='h-4 w-4 mr-2' /> More
                        </ButtonWithAds>
                    )}
                    <Button
                        onClick={shareToClipboard}
                        variant='secondary'
                        size='icon'
                        className='cursor-pointer share-content'
                    >
                        <Share2 className='h-4 w-4' />
                    </Button>
                </CardFooter>
            )}
        </Card>
    )
}

export default ContentCard
