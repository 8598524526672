import * as React from 'react'

import { Button, ButtonProps } from '@/components/ui/button'
import { useExternalAds } from '@/provider/external-ads-provider'

const ButtonWithAds = React.forwardRef<HTMLButtonElement, ButtonProps>(({ ...props }, ref) => {
    const { openAds } = useExternalAds()

    const openAdsHook = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!openAds(e)) {
            props.onClick?.(e)
        }
    }

    return <Button {...props} ref={ref} onClick={openAdsHook} />
})
ButtonWithAds.displayName = 'ButtonWithAds'

export { ButtonWithAds }
