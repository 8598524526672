import React from 'react'

import ContentCard, { CardType, DisplayMode } from '@/components/Content/ContentCard'

export const ContentGridLoading = () => {
    return (
        <div className='container mx-auto px-4'>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-3 gap-4 '>
                {new Array(8).fill(undefined).map((_, i) => (
                    <ContentCard
                        key={i}
                        displayMode={DisplayMode.CONTAIN}
                        contentData={undefined}
                        cardType={CardType.CONTENT_GRID}
                    />
                ))}
            </div>
        </div>
    )
}

export default ContentGridLoading
