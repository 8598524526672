export enum ContentSorting {
    NEWEST = 'NEWEST',
    OLDEST = 'OLDEST',
    HOT = 'HOT',
    VIEWS_ALL_TIME = 'VIEWS_ALL_TIME',
    RANDOM = 'RANDOM',
    SIZE = 'SIZE',
    USER_VIEWED = 'USER_VIEWED',
}

export enum Ethnicity {
    ASIAN = 'asian',
    BLACK = 'black',
    LATINO = 'latino hispanic',
    WHITE = 'white',
    ARAB = 'middle eastern',
    INDIAN = 'indian',
    ALL = 'all',
}

export interface ContentPage {
    content: Content[]
    count: number
    cursorId: string
}

export interface LoginRes {
    token: string
}

export interface APIError {
    statusCode: number
    message: string
}

export interface BalanceRes {
    balance: number
    mask_credits: boolean
    email: string
    anonymous: boolean
    frame: string
    frameCounter: number
    restock: number
    validUntil?: Date

    planTitle: string
    planFree: boolean
}

export interface Content {
    createdAt: Date
    views: number
    shortCode: string
    id: string
    media: string
    tags: string[]
    viewed?: boolean
    isPremium?: boolean
    title: string
    mediaAspectRatio: number

    info: MegaInfo
}

export interface MegaInfo {
    videos: number
    images: number
    size: number
    updatedAt: Date
}

export interface PlanData {
    planTitle: string
    planFree: boolean
    mask_credits: boolean
    frame: string
    frameCounter: number
    restock: number
    price: number
}
