import { ShoppingCart } from 'lucide-react'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Separator } from '@/components/ui/separator'
import { useCredits } from '@/provider/credit-provider'

export const AdBlockerDetector = () => {
    const { creditConfig, isFetching } = useCredits()
    const [showMessage, setShowMessage] = useState(false)
    const checkAdblocker = () => {
        fetch('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', { redirect: 'manual' })
            .then((response) => {
                if (response.type === 'opaqueredirect' || !response.ok) {
                    setShowMessage(true)
                }
            })
            .catch(() => setShowMessage(true))

        setTimeout(() => {
            // if (testElStyle.display === 'none' || testElStyle.visibility === 'hidden') {
            //     setShowMessage(true)
            // }
        }, 1500)
    }
    useEffect(() => {
        if (!isFetching && creditConfig.planFree) checkAdblocker()
    }, [creditConfig, isFetching])

    const reloadPage = () => {
        window.location.reload()
    }

    return (
        <Dialog open={showMessage}>
            <DialogContent className='sm:max-w-[425px]'>
                <DialogHeader>
                    <DialogTitle>Please Disable Your Adblock</DialogTitle>
                    <DialogDescription>
                        Please disable your ad blocker to access this website. If you see this message
                        again, consider using a different browser.
                        <Separator className='mt-2 mb-2' />
                        With Premium you can use the Site with AdBlocker and completely ad free.
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button className='cursor-pointer w-full' variant='default' asChild>
                        <Link href={`/plans`}>
                            <ShoppingCart className='h-4 w-4 mr-2' />
                            Get Premium
                        </Link>
                    </Button>
                    <Button variant='secondary' onClick={reloadPage}>
                        I have disabled my Adblocker
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
