export const MegaIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000' {...props}>
        <path
            fill='currentColor'
            d='M512 64C264.571 64 64 264.571 64 512s200.571 448 448 448 448-200.571 448-448S759.429 64 512 64zm232.553 606.424a13.861 13.861 0 01-13.883 13.884h-58.51a13.861 13.861 0 01-13.884-13.884V490.183a1.77 1.77 0 00-2.976-1.24L531.338 612.905a27.684 27.684 0 01-38.924 0L368.452 488.943a1.797 1.797 0 00-2.976 1.24v180.24a13.861 13.861 0 01-13.883 13.885h-58.51a13.861 13.861 0 01-13.884-13.884V353.576a13.861 13.861 0 0113.883-13.884h40.164a28.013 28.013 0 0119.586 8.182l154.21 154.209a6.736 6.736 0 009.669 0l154.209-154.21a27.577 27.577 0 0119.586-8.18h40.164a13.861 13.861 0 0113.883 13.883z'
        />
    </svg>
)

export default MegaIcon
